import React from "react"
import { Link, graphql } from "gatsby"
import Img from "gatsby-image"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import tw, { styled } from "twin.macro"

import Bio from "../components/bio"
import Layout from "../components/layout"
import SEO from "../components/seo"

// responsive styles for articles are here
const StyledArticle = styled.article`
  ${tw`prose lg:prose-lg mx-8 sm:mx-16 md:mx-24 lg:mx-40 py-4`}
`

const StyledH1 = styled.h1`
  ${tw`mb-0 pt-8 tracking-tighter`}
`

const StyledDateP = styled.p`
  ${tw`block pb-2`}
`

const StyledFooter = styled.footer`
  ${tw`sm:mx-auto xs:mx-4 my-0`}
  max-width: 65ch;
`

const StyledUl = styled.ul`
  ${tw`flex flex-wrap justify-between p-0`}
  list-style: none;
`

const BlogPostContentfulTemplate = ({ data, pageContext }) => {
  const post = data.contentfulPost
  const siteTitle = data.site.siteMetadata.title
  const { previous, next } = pageContext

  return (
    <Layout title={siteTitle}>
      <SEO
        title={post.title}
        description={post.subtitle}
      />

      {post.image &&
        <Img
          fluid={post.image.fluid}
          style={{
            maxWidth: '1088px',
            width: '100%',
            // maxHeight: '800px',
            margin: '0 auto'
          }}
          imgStyle={{
            objectFit: 'contain'
          }}
          // key={}
          alt={post.image.description || post.image.title || ''}
        />
      }

      <StyledArticle>
        <header>
          <StyledH1>
            {post.title}
          </StyledH1>
          <StyledDateP>
            {post.date}
          </StyledDateP>
        </header>

        <section>
          {documentToReactComponents(post.content.json)}
        </section>

        <hr/>

      </StyledArticle>

      <StyledFooter>
        <Bio />
      </StyledFooter>

      <nav>
        <StyledUl>
          <li>
            {previous && (
              <Link to={`/${previous.slug}`} rel="prev">
                ← {previous.title}
              </Link>
            )}
          </li>
          <li>
            {next && (
              <Link to={`/${next.slug}`} rel="next">
                {next.title} →
              </Link>
            )}
          </li>
        </StyledUl>
      </nav>
    </Layout>
  )
}

export default BlogPostContentfulTemplate

export const pageQuery = graphql`
  query ContentfulBlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    contentfulPost( slug: { eq: $slug }) {
      title
      subtitle
      author
      image {
        fluid {
          ...GatsbyContentfulFluid
        }
        title
        description
      }
      date
      content {
        json
      }
    }
  }
`
