import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Image from "gatsby-image"
import tw, { styled } from "twin.macro"

const StyledBioContainer = styled.div`
  ${tw`flex my-12`}
`

const StyledImage = styled(Image)`
  ${tw`mb-0 mr-3 p-6`}
  min-width: 50;
  border-radius: 100%;
`

const StyledP = styled.p`
  ${tw`prose`}
`

const StyledA = styled.a`
  ${tw`hover:text-blue-700 hover:no-underline`}
`

const Bio = () => {
  const data = useStaticQuery(graphql`
    query BioQuery {
      avatar: file(absolutePath: { regex: "/wsf-logo.png/" }) {
        childImageSharp {
          fixed(width: 50, height: 50, quality: 95) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      site {
        siteMetadata {
          author {
            name
            summary
          }
          social {
            instagram
          }
        }
      }
    }
  `)

  const { author, social } = data.site.siteMetadata
  return (
    <StyledBioContainer>
      <StyledImage
        fixed={data.avatar.childImageSharp.fixed}
        alt={author.name}
        imgStyle={{
          borderRadius: `50%`,
        }}
      />
      <StyledP>
        Written by <strong>{author.name}</strong> {author.summary}
        {` `}
        <StyledA href={`https://instagram.com/${social.instagram}`} target="_blank" rel="noopener noreferrer">
          {author.name} on Instagram
        </StyledA>
      </StyledP>
    </StyledBioContainer>
  )
}

export default Bio
